/* eslint-disable no-undef */
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";

const MoreSaticfaction = () => {
  let myIndex = 0;

  const images = [
    { src: require("../../files/banner-2-scaled.jpg") },
    { src: require("../../files/banner-3-2.jpg") },
    {
      src: require("../../files/BOSSE-Board-Of-open-Schooling-And-Skill-Education.jpg"),
    },
  ];

  function carousel() {
    let i;
    let x = document.getElementsByClassName("mySlidess");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    myIndex++;
    if (myIndex > x.length) {
      myIndex = 1;
    }
    x[myIndex - 1].style.display = "block";
    setTimeout(carousel, 4000);
  }

  useEffect(() => {
    carousel();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div className="main_contentDiv">
      <div className="content" style={{ zIndex: "-1", position: "relative" }}>
        <div className="slideshow-container" style={{ marginTop: "1rem" }}>
          {images.map((item) => (
            <div className="mySlidess fade" key={id}>
              <img alt="#" src={item.src} style={{ width: "100%" }} />
            </div>
          ))}
        </div>
      </div>
      <Box
        padding={"1rem"}
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          marginTop: "1rem",
        }}
      >
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          12th (HSC) Higher Secondary Program
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          <h6 className="mainhading" style={{ fontSize: "2.5vh" }}>
            {" "}
            Senior Secondary Programme is equivalent to XIIth standard. Those
            who have successfully passed Secondary are eligible to take
            admission to Sr. Secondary Programme of BOSSE. All applicants need
            to submit a self-attested copy of their Secondary Certificate. There
            is no restriction on choice of subjects and combinations, except
            that minimum one language has to be taken. Learners can choose their
            subjects from the BOSSE List of Subjects as given below.
          </h6>
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          <a
            href="https://www.youtube.com/watch?v=mCyqVaxIX4U"
            target="_blank"
            rel="noopener noreferrer"
          >
            Link to YouTube video
          </a>
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          <a
            href="https://www.youtube.com/watch?v=zDqwOg1hpDs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Link to YouTube video
          </a>
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          <a
            href="https://www.youtube.com/watch?v=4s-QpnZJ3Ns"
            target="_blank"
            rel="noopener noreferrer"
          >
            Link to YouTube video
          </a>
        </Typography>
      </Box>

      <Box
        padding={"1rem"}
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          marginTop: "1rem",
        }}
      >
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          BOSSE organized National Conference on “NEP 2020"
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          <a
            href="https://www.youtube.com/watch?v=mCyqVaxIX4U"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click to Watch{" "}
          </a>
        </Typography>
      </Box>

      <Box
        padding={"1rem"}
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          marginTop: "1rem",
        }}
      >
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          BOSSE BOARD NEW UPDATES #LISTED IN COBSE #ADMISSION OPEN APRIL
          2023#FULLY VALID BOARD
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          <a
            href="https://www.youtube.com/watch?v=zDqwOg1hpDs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click to Watch{" "}
          </a>
        </Typography>
      </Box>

      <Box
        padding={"1rem"}
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          marginTop: "1rem",
        }}
      >
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          HOW TO CHECK BOSSE SIKKIM BOARD RECOGNITION & APPROVALS ?
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          <a
            href="https://www.youtube.com/watch?v=4s-QpnZJ3Ns"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click to Watch
          </a>
        </Typography>
      </Box>
    </div>
  );
};

export default MoreSaticfaction;
