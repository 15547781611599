import React from 'react'

function AboutUs() {
    return (
        <>
            <h1 style={{ marginTop: '3rem', marginLeft: '3rem' }}>About Us </h1>
            <div style={{ marginTop: '2rem', marginLeft: '5rem', marginRight: '5rem', marginBottom: '2rem', fontSize: '1.2rem', textAlign: "justify" }}>
                <p>

                    Welcome! We take pride in providing you with information about an online distance learning institute. We are here to meet your educational needs in the era of transformation in education.              </p>
                <p>
                    Our team of experts, trained educators, and technology professionals aim to provide you with an educational platform that can deliver high-quality instruction with just a few clicks.
                </p>
                <p>

                    Our shared goal is to offer you a relevant and diverse range of materials that align with your personal preferences. Our courses encompass a wide range, catering to individuals of all ages, educational levels, and subjects.

                </p>
                <p>
                    We are dedicated to guiding you in technical training, personal development, skill enhancement, and career education. We build upon our expertise to provide you with collaborations with reputable institutions and a robust network.

                </p>
                <p>
                    We value your experiences and ensure that our learning experience resonates with you. We appreciate your feedback and strive to deliver a trustworthy educational experience to our students.
                </p>
                <p>
                    We hope you'll be pleased to be a part of our online distance learning institute. We are committed to providing you with quality education and an impactful learning experience to empower you in your journey of personal growth.

                </p>
                <p>If you have any questions about us, please don't hesitate to contact us. We eagerly await the opportunity to address your inquiries. Thank you!</p>
            </div>
        </>
    )
}

export default AboutUs
