import React from "react";
import SignUp from "./SignUp";



function StudentFrom() {
  return (
    <div
      className="container mt-3 form"
      style={{ backgroundColor: "lavender" }}
    >
      <div className="row justify-content-center">
        <div className="col-md-5 ">
          <SignUp />
        </div>
      </div>
    </div>
  );
}

export default StudentFrom;
