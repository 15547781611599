import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";


export default function CounciL() {

  const certificates = [
    "ASIAN INTERNATIONAL UNIVERSITY AUTHORITY",
    "AICVPS  AUTHORITY",
    "UGC  With  AIU",
    "SIKKIM SKILL UNIVERSITY",
    "ASIAN INTERNATIONAL UNIVERSITY",

  ];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }





  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;

  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          style={{
            color: "white",
            display: "inline-block",
            textAlign: "center",
            padding: "17px 27px",
            fontWeight: "900",
            fontSize: "12.5px",
            textTransform: "capitalize",
            fontFamily:
              "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
          }}
          ref={anchorRef}
          onClick={handleToggle}
        >
          Institute Affiliations
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    style={{
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                      height: "246px",
                      width: "263px",
                      zIndex: "1000",
                      backgroundColor: "black",
                    }}
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <a
                      className="hovercolor"
                      rel="noreferrer"
                      style={{ position: "relative", overflow: "visible" }}
                      download="Syllabus.pdf"
                      href={require("../../../paramedicalSyllabus/0.pdf")}
                      target="_blank"
                    >
                      {certificates[0]}
                    </a>
                    <a
                      className="hovercolor"
                      rel="noreferrer"
                      style={{ position: "relative", overflow: "visible" }}
                      download="Syllabus.pdf"
                      href={require("../../../paramedicalSyllabus/1.pdf")}
                      target="_blank"
                    >
                      {certificates[1]}
                    </a>
                    <a
                      className="hovercolor"
                      rel="noreferrer"
                      style={{ position: "relative", overflow: "visible" }}
                      download="Syllabus.pdf"
                      href={require("../../../paramedicalSyllabus/2.pdf")}
                      target="_blank"
                    >
                      {certificates[2]}
                    </a>
                    <a
                      className="hovercolor"
                      rel="noreferrer"
                      style={{ position: "relative", overflow: "visible" }}
                      download="Syllabus.pdf"
                      href="https://www.youtube.com/watch?v=GqzJp60DT-A"
                      target="_blank"
                    >
                      {certificates[3]}
                    </a>
                    <a
                      className="hovercolor"
                      rel="noreferrer"
                      style={{ position: "relative", overflow: "visible" }}
                      download="Syllabus.pdf"
                      href="https://www.youtube.com/watch?v=Yhrl3Nzh-5A"
                      target="_blank"
                    >
                      {certificates[4]}
                    </a>



                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
