import { Box, Typography } from '@mui/material'
import React from 'react'

function MissionAndVision() {

  return (
    <>
      <Typography
        variant="h2"
        color={"whitesmoke"}
        fontWeight="700"
        bgcolor='#001f5f'
        fontFamily="system-ui"
        textAlign={'center'}
        sx={{ m: "0 0 5px 0" }}
      >
        Welcome to Sir Syed Institute of Distance Learning
      </Typography>
      <Box padding={'1rem'}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="700"
          sx={{ m: "0 0 5px 0" }}
        >
          Mission
        </Typography>
        <Typography variant="h5" color={"ffff"}>
          At Sir Sayed Ahemad Khan Online Distance Learning Institute, our mission is to provide accessible and top-quality education through online distance learning. We strive to empower learners worldwide, breaking barriers of time and location. With a focus on flexibility, innovation, and expert guidance, we are dedicated to helping students achieve their academic and professional aspirations. Join us on this transformative journey towards knowledge and growth.
        </Typography>

      </Box>
      <Box padding={'1rem'}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          Vision
        </Typography>
        <Typography variant="h5" color={"ffff"}>
          Our vision at Sir Sayed Ahemad Khan Online Distance Learning Institute is to be a global leader in online distance learning, setting new standards of excellence in education. We aspire to create a world where access to knowledge knows no boundaries, enabling learners from all walks of life to fulfill their potential and contribute meaningfully to society. Through continuous innovation, cutting-edge technology, and a commitment to student success, we aim to revolutionize the way education is delivered and experienced, making learning a truly enriching and empowering journey for every individual we serve.
        </Typography>

      </Box>
    </>
  )
}

export default MissionAndVision