import React from "react";
import "./App.css";
import MainContent from "./component/maincontent/MainContent";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import StudentFrom from "./component/admissionform/StudentFrom";
import StudentInfo from "./component/studentInfo/StudentInfo";
import Login from "./component/AdminLogin/Login";
import SingUpPage from "./component/singUpPage/SingUpPage";
import Users from "./component/table/table";
import AboutUs from "./component/contactus/AboutUs";
import ContactUs from "./component/contactus/ContactUs";
import DirectorDesk from "./component/contactus/DirectorDesk";
import PravicyPolisy from "./component/contactus/PravicyPolisy";
import PaymentPage from "./component/payment/PaymentPage";
import Root from "./component/Root/Root";
import AdminProtected from "./component/Root/AdminProtected";
import ELearnig from "./component/contactus/ELearnig";
import PgCourse from "./component/courses-table/PgCourse";
import DiplomaCourses from "./component/courses-table/DiplomaCourses";
import UgCourse from "./component/courses-table/UgCourses";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useMode } from "./theme";
import PressRelease from "./component/press-realease/PressRelease";
import MissionAndVision from "./component/contactus/MissionAndVision";
import AimsAndObjectives from "./component/contactus/AimsAndObjectives"
import OurScope from "./component/contactus/OurScope"
import Condition from "./component/contactus/Condition"
import JobAndInter from "./component/press-realease/JobAndInter";
import MoreAbout from "./component/contactus/MoreAbout"
import TenthDetailpage from "./component/contactus/TenthDetailpage";
import TwoelthDetail from "./component/contactus/12thDetail";
import SyllubusFeesPage from "./component/contactus/SyllubusFeesPage";
import BordDetails from "./component/navbar/dropDown/BordDetails";
import TwoelhtFesstable from "./component/contactus/12thFesstable";
import MoreSaticfaction from "./component/contactus/MoreSaticfaction";
import Tremcondition from "./component/contactus/Tremcondition";
import MIssionVission from "./component/contactus/MIssionVission";
import LastComputer from "./component/contactus/LastComputer";


function App() {
  const [theme] = useMode();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/login" element={<Login />} />
        <Route path="/Payment" element={<PaymentPage />} />
        <Route path="/Studentlogin" element={<SingUpPage />} />

        <Route element={<Root />}>
          <Route path="/" element={<MainContent />} />
          <Route path="/admission" element={<StudentFrom />} />
          <Route path="/studentInfo" element={<StudentInfo />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/DirectorDesk" element={<DirectorDesk />} />
          <Route path="/PravicyPolisy" element={<PravicyPolisy />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path='/ELearning' element={<ELearnig />} />
          <Route path='/diploma' element={<DiplomaCourses />} />
          <Route path='/ugCourse' element={<UgCourse />} />
          <Route path='/pgCourse' element={<PgCourse />} />
          <Route path="/PressRelease" element={<PressRelease />} />
          <Route path="/JobAndInter" element={<JobAndInter />} />
          <Route path="/MissionAndVision" element={<MissionAndVision />} />
          <Route path="/AimsAndObjectives" element={<AimsAndObjectives />} />
          <Route path="/OurScope" element={<OurScope />} />
          <Route path="/Condition" element={<Condition />} />
          <Route path="/MoreAbout" element={<MoreAbout />} />
          <Route path='/TenthDetailpage' element={<TenthDetailpage />} />
          <Route path="/TwoelthDetail" element={<TwoelthDetail />} />
          <Route path="/SyllubusFeesPage" element={<SyllubusFeesPage />} />
          <Route path="/BordDetails" element={<BordDetails />} />
          <Route path="/TwoelhtFesstable" element={<TwoelhtFesstable />} />
          <Route path="/MoreSaticfaction" element={<MoreSaticfaction />} />
          <Route path="/Tremcondition" element={<Tremcondition />} />
          <Route path="/MIssionVission" element={<MIssionVission />} />
          <Route path="/LastComputer" element={<LastComputer />} />
        </Route>
        <Route element={<AdminProtected />}>
          <Route path="/table" element={<Users />} />
        </Route>
      </Route>
    )
  );


  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router}></RouterProvider>
      </ThemeProvider>

    </>
  );
}

export default App;
