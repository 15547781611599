import React from 'react'

const TwoelhtFesstable = () => {
  return (
    <div>

      <div className="Table responsive class mx-auto overflow-auto mt-1" style={{ width: '80%' }}>
        <caption className='Captions' >Following Table Shows The Subject Names in Curriculum of HSC / XIIth in BOSSE.</caption>
        <table className="table table-bordered shadow">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Detail</th>
              <th scope="col">Secondary Course</th>
              <th scope="col">Sr Secondary Course</th>
              <th scope="col">Other Fee</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Registration Fess</td>
              <td>Rs.1000/-(For Five Years)</td>
              <td>Rs.1000/-(For Five Years)</td>
              <td>Cost of information Brochure (prospectus) along with the application Form from the office of Bosse.  250</td>
            </tr>
            <tr>
              <td>Programmme Fees For 5 Subject </td>
              <td>Rs.6000 </td>
              <td>Rs.7000</td>
              <td>Re-evaluation of answer script per paper 500</td>
            </tr>
            <tr>
              <td>For each additional subject</td>
              <td>Rs.1200</td>
              <td>Rs.1400</td>
              <td>Fee for change in examination center </td>
            </tr>
            <tr>
              <td>Practical Fee per subject (for subject with paratoicals)</td>
              <td>Rs.300</td>
              <td>Rs.600</td>
              <td>Consolidated marks card / Migration Certificate</td>
            </tr>
            <tr>
              <td>Examination fee (per subject)</td>
              <td>Rs.300</td>
              <td>Rs.300</td>
              <td>Verification of certificate / Authenticity of certificate for domastic Institute/Empolyers /individual learner</td>
            </tr>
            <tr>
              <td>Additional fees for practical exam</td>
              <td>Rs.180 per subject</td>
              <td>Rs.180 per subject</td>
              <td>Transcription/verification for foreign institutions/University/Empployeras</td>
            </tr>






          </tbody>
        </table>
      </div></div>
  )
}

export default TwoelhtFesstable