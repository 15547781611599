import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/Footer";
import img1 from "../../AllEnrolmainpdf/C.M.S.-ED-GR-1.jpg";
import img2 from "../../AllEnrolmainpdf/C.M.S.-ED-GR-2.jpg";
import img3 from "../../AllEnrolmainpdf/C.M.S.-ED-GR-3.jpg";
import img4 from "../../AllEnrolmainpdf/C.M.S.-ED-GR-4.jpg";
import img5 from "../../AllEnrolmainpdf/C.M.S.-ED-GR-5.jpg";
import img6 from "../../AllEnrolmainpdf/gr2-1.jpg";
import img7 from "../../AllEnrolmainpdf/gr2-2.jpg";
import img8 from "../../AllEnrolmainpdf/gr2-3.jpg";
import img9 from "../../AllEnrolmainpdf/gr2-4.jpg";
import img10 from "../../AllEnrolmainpdf/gr2-5.jpg";
import img11 from "../../AllEnrolmainpdf/gr3-1.jpg";
import img12 from "../../AllEnrolmainpdf/gr3-2.jpg";
import img13 from "../../AllEnrolmainpdf/gr3-3.jpg";
import img14 from "../../AllEnrolmainpdf/gr4-1.jpg";
import img15 from "../../AllEnrolmainpdf/gr4-2.jpg";
import img16 from "../../AllEnrolmainpdf/gr4-3.jpg";
import img17 from "../../AllEnrolmainpdf/gr4-4.jpg";
import img18 from "../../AllEnrolmainpdf/gr4-5.jpg";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import Asian from "../../images/AIU Brochure.pdf";
import Brocher from "../../../src/Allpdf/02-سر سید پراسپیکٹس 2024.pdf";
import sikkim from "../../Allpdf/skmsuniv.pdf";
import axios from "axios";
import AICVPSe from "../../../src/Allpdf/03-ADM FORM KAUSHALYA BOARD.pdf";
import Asiane from "../../images/AIU Admisison Form (1).pdf";
import sikkime from "../../images/SIKKIM SKILL UNIVERSITY ADMISSION FORM.pdf";
import bosse from "../../images/Ojas Bahu Uddeshiye Kalimoddin Shaikh.jpg";
import { Swiper, SwiperSlide } from "swiper/react";

const MainContent = () => {
  const [slideIndex, setSlideIndex] = useState(1);
  const [myIndex, setMyIndex] = useState(1);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [newsData, setNewsData] = useState([]);

  const fetchCoursedata = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL_DEV}/getNewBytype?type=exam`)
      .then((res) => {
        setNewsData(res.data.data);
        console.log(res.data);
      });
  };
  useEffect(() => {
    fetchCoursedata();
  }, []);

  const images = [
    { src: require("../../files/Slide1.JPG"), id: "103" },
    { src: require("../../files/Slide2.JPG"), id: "104" },
    { src: require("../../files/Slide3.JPG"), id: "105" },
    { src: require("../../files/Slide4.JPG"), id: "106" },
    { src: require("../../files/Slide5.JPG"), id: "107" },
    { src: require("../../files/Slide6.JPG"), id: "108" },
    { src: require("../../files/Slide7.JPG"), id: "109" },
    { src: require("../../files/Slide8.JPG"), id: "110" },
    { src: require("../../files/Slide9.JPG"), id: "111" },
    { src: require("../../files/Slide10.JPG"), id: "112" },
    { src: require("../../files/Slide11.JPG"), id: "113" },
    { src: require("../../files/Slide12.JPG"), id: "114" },
    { src: require("../../files/Slide13.JPG"), id: "115" },
    { src: require("../../files/Slide14.JPG"), id: "116" },
    { src: require("../../files/Slide15.JPG"), id: "117" },
    { src: require("../../files/Slide16.JPG"), id: "118" },
    { src: require("../../files/Slide17.JPG"), id: "119" },
    { src: require("../../files/Slide18.JPG"), id: "120" },
    { src: require("../../files/Slide19.JPG"), id: "121" },
    { src: require("../../files/Slide20.JPG"), id: "122" },
    { src: require("../../files/Slide21.JPG"), id: "123" },
    { src: require("../../files/Slide22.JPG"), id: "124" },
    { src: require("../../files/Slide24.JPG"), id: "125" },
    { src: require("../../files/Slide25.JPG"), id: "126" },
    { src: require("../../files/Slide26.JPG"), id: "127" },
    { src: require("../../files/Slide27.JPG"), id: "128" },
    { src: require("../../files/Slide28.JPG"), id: "129" },
    { src: require("../../files/Slide29.JPG"), id: "130" },
    { src: require("../../files/Slide30.JPG"), id: "131" },
    { src: require("../../files/Slide31.JPG"), id: "132" },
    { src: require("../../files/Slide32.JPG"), id: "133" },
    { src: require("../../files/Slide33.JPG"), id: "134" },
    { src: require("../../files/Slide34.JPG"), id: "107" },
    { src: require("../../files/Slide35.JPG"), id: "107" },
    { src: require("../../files/Slide36.JPG"), id: "107" },
    { src: require("../../files/Slide37.JPG"), id: "107" },
    { src: require("../../files/Slide38.JPG"), id: "107" },
    { src: require("../../files/Slide39.JPG"), id: "107" },
  ];
  const imageSources = [
    require("../../MainContainImages/Slide1.PNG"),
    require("../../MainContainImages/Slide3.PNG"),
    require("../../MainContainImages/Slide4.PNG"),
    require("../../MainContainImages/Slide5.PNG"),
    require("../../MainContainImages/Slide6.PNG"),
    require("../../MainContainImages/Slide7.PNG"),
    require("../../MainContainImages/Slide8.PNG"),
    require("../../MainContainImages/Slide9.PNG"),
    require("../../MainContainImages/Slide10.PNG"),
    require("../../MainContainImages/Slide11.PNG"),
    require("../../MainContainImages/Slide12.PNG"),
    require("../../MainContainImages/Slide13.PNG"),
    require("../../MainContainImages/Slide14.PNG"),
    require("../../MainContainImages/Slide16.PNG"),
    require("../../MainContainImages/Slide17.PNG"),
    require("../../MainContainImages/Slide18.PNG"),
    require("../../MainContainImages/Slide19.PNG"),
    require("../../MainContainImages/Slide20.PNG"),
    // Add more image sources here
  ];

  function carousel() {
    let i;
    let x = document.getElementsByClassName("mySlides");
    console.log(x.length);
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    setMyIndex((prevIndex) => {
      let newIndex = prevIndex + 1;
      if (newIndex > x.length) {
        newIndex = 1;
      }
      return newIndex;
    });
    x[myIndex - 1].style.display = "block";
  }

  function showSlides() {
    let i;
    let slides = document.getElementsByClassName("mySlidess");

    let dots = document.getElementsByClassName("dot");
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    setSlideIndex((prevIndex) => {
      let newIndex = prevIndex + 1;
      if (newIndex > slides.length) {
        newIndex = 1;
      }
      return newIndex;
    });
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i]?.className.replace("active", "");
    }
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
  }

  useEffect(() => {
    // showSlides();
    // carousel();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  });

  const currentImage = images[currentImageIndex];

  return (
    <div className="main_contentDiv">
      <div className="content" style={{ zIndex: "-1", position: "relative" }}>
        <div className="slideshow-container" style={{ marginTop: "1rem" }}>
          <>
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              slidesPerView={1}
              navigation
              mousewheel={{
                forceToAxis: true,
              }}
              autoplay={{ delay: 10000 }}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {imageSources.map((item, index) => (
                <SwiperSlide key={index + "dsfsfdf"}>
                  <div className={`mySlides`} key={index + "sdfsafasdf"}>
                    <img
                      alt={`Slide ${index + 1}`}
                      src={item}
                      style={{ width: "100%" }}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        </div>
        <br />

        <div style={{ textAlign: "center" }}>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>

        <div class="list-container">
          <ul class="list">
            {newsData.map((item) => {
              return <li key={item._id}>{item.descripstion}</li>;
            })}
          </ul>
        </div>

        <div className="content_main">
          <div className="one">
            <div className="menu">
              <h2>Menu</h2>
              <Link className="visible-class" to={"/MissionAndVision"}>
                {" "}
                Mission & Vision
              </Link>
              <Link className="visible-class" to={"/AimsAndObjectives"}>
                Aim & Objective
              </Link>
              <Link className="visible-class" to={"/OurScope"}>
                Our Scope
              </Link>

              <div className="dropdown show">
                <a
                  className="btn btn-secondary dropdown-toggle propectus-link text-start"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Course Details
                </a>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link to="/TwoelthDetail" style={{ fontSize: "1.8vh" }}>
                    {" "}
                    DETAILS OF 10TH
                  </Link>
                  <Link to="/TenthDetailpage" style={{ fontSize: "1.8vh" }}>
                    {" "}
                    DETAILS OF 12TH
                  </Link>
                  <Link to="/diploma" style={{ fontSize: "1.8vh" }}>
                    {" "}
                    DIPLOMA COURSES
                  </Link>
                  <Link to="/ugCourse" style={{ fontSize: "1.8vh" }}>
                    {" "}
                    UG-GRADUATION{" "}
                  </Link>
                  <Link to="/pgCourse" style={{ fontSize: "1.8vh" }}>
                    {" "}
                    PG-POST GRADUATION{" "}
                  </Link>
                  <Link to="/LastComputer">LATEST COMPUTER COURSES (PAGE)</Link>
                </div>
              </div>

              <Link to="/ELearning">E-Learning & Self Study Mode</Link>
            </div>
            <div className="institution">
              <h2>Institution</h2>
              <a
                href="https://forms.gle/xJiHqhQhLcKwjxxp9"
                target="_blank"
                rel="noreferrer"
              >
                Apply For From Collection Center(ACC){" "}
              </a>
              <a
                href="https://forms.gle/cY7nZRWNCHdKWWUT9"
                target="_blank"
                rel="noreferrer"
              >
                Apply For Online Faculty
              </a>
              <a
                href="https://tinyurl.com/vzsju23m"
                target="_blank"
                rel="noreferrer"
              >
                Apply For 10th
              </a>
              <a
                href="https://tinyurl.com/vzsju23m"
                target="_blank"
                rel="noreferrer"
              >
                Apply For 12th
              </a>
              <a
                href="https://tinyurl.com/yb9dayh5"
                target="_blank"
                rel="noreferrer"
              >
                BOSSE-RTI Act From Universities
              </a>
              <a
                href="https://tinyurl.com/msbv2er5"
                target="_blank"
                rel="noreferrer"
              >
                Download 10th/12th Brochure-2023-24{" "}
              </a>
              <Link to="/JobAndInter">Jobs & Advertisement </Link>
              <Link to="/PressRelease">Press Release</Link>
            </div>
            <div className="nb">
              <h2>Notice Board</h2>
              <div direction="up" className="marquee">
                <div className="marquee-content">
                  <a download="RES1-file.png" href={img1}>
                    CMS & ED GOVT. RES 1
                  </a>
                  <a download="GOVT-RES-file1.png" href={img2}>
                    CMS & ED GOVT. RES 2
                  </a>
                  <a download="GOVT-RES-file2.png" href={img3}>
                    CMS & ED GOVT. RES 3
                  </a>
                  <a download="GOVT-RES-file3.png" href={img4}>
                    CMS & ED GOVT. RES 4
                  </a>
                  <a download="GOVT-RES-file4.png" href={img5}>
                    CMS & ED GOVT. RES 5
                  </a>
                  <a download="GOVT-RES-file5.png" href={img6}>
                    CMS & ED GOVT. RES 6
                  </a>
                  <a download="GOVT-RES-file6.png" href={img7}>
                    CMS & ED GOVT. RES 7
                  </a>
                  <a download="GOVT-RES-file7.png" href={img8}>
                    CMS & ED GOVT. RES 8
                  </a>
                  <a download="GOVT-RES-file8.png" href={img9}>
                    CMS & ED GOVT. RES 9
                  </a>
                  <a download="GOVT-RES-file9.png" href={img10}>
                    CMS & ED GOVT. RES 10
                  </a>
                  <a download="GOVT-RES-file10.png" href={img11}>
                    CMS & ED GOVT. RES 11
                  </a>
                  <a download="GOVT-RES-file11.png" href={img12}>
                    CMS & ED GOVT. RES 12
                  </a>
                  <a download="GOVT-RES-file12.png" href={img13}>
                    CMS & ED GOVT. RES 13
                  </a>
                  <a download="GOVT-RES-file13.png" href={img14}>
                    CMS & ED GOVT. RES 14
                  </a>
                  <a download="GOVT-RES-file14.png" href={img15}>
                    CMS & ED GOVT. RES 15
                  </a>
                  <a download="GOVT-RES-file15.png" href={img16}>
                    CMS & ED GOVT. RES 16
                  </a>
                  <a download="GOVT-RES-file16.png" href={img17}>
                    CMS & ED GOVT. RES 17
                  </a>
                  <a download="GOVT-RES-file17.png" href={img18}>
                    CMS & ED GOVT. RES 18
                  </a>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="swarajya">
            <div className="swarajya_info">
              <h2>Sir Syed Institute Of Distance Learning </h2>
              <p>
                <b>Welcome to Sir Syed Institute.</b>
              </p>
              <>
                <p>
                  Sir Syed Institute of Distance Learning in India, We Provide a
                  Platform for those Student who are on Job, and want to
                  continue their education in various sectors like, Paramedical,
                  Traditional, Computer Science, Business Administration, Home
                  Science, Life Science etc.
                </p>
                <p>
                  As Paramedical education has emerged as an essential component
                  of the healthcare industry in India. With the increasing
                  demand for healthcare services, the scope of paramedical
                  education has also grown significantly in recent years. Today,
                  paramedical education in India offers a wide range of courses
                  that prepare students to assist doctors and other healthcare
                  professionals in various medical procedures.
                </p>

                <h5>
                  Some of the popular courses in paramedical education in India
                  include
                </h5>
                <h6>1. Bachelor of Physiotherapy (BPT)</h6>
                <h6>2. Bachelor of Occupational Therapy (BOT)</h6>
                <h6>
                  3. Bachelor of Science in Medical Laboratory Technology (BMLT)
                </h6>
                <h6>
                  4. Post Graduate Diploma in Medical Laboratory Technology
                  (PGDMLT)
                </h6>
                <h6>
                  5. Bachelor of Science in Radiology and Imaging Technology
                  (BSc RIT)
                </h6>
                <h6>6. Diploma in Nursing</h6>
                <h6>7. Diploma in Medical Laboratory Technology (DMLT)</h6>
                <h6>8. Diploma in Radiology and Imaging Technology (DRIT)</h6>
                <h6>9. E.T.C</h6>

                <p>
                  These courses equip students with the necessary knowledge and
                  skills to perform a range of diagnostic, therapeutic, and
                  supportive roles in the healthcare industry. The demand for
                  paramedical professionals in India is expected to grow at a
                  rapid pace in the coming years, as the healthcare industry
                  continues to expand.
                </p>
                <p>
                  In conclusion, the scope of paramedical education in India is
                  immense, with plenty of opportunities for students who wish to
                  pursue a career in healthcare. With the increasing demand for
                  healthcare services and the growing need for skilled
                  healthcare professionals, paramedical education has become an
                  attractive option for many students in India.
                </p>
              </>
            </div>
          </div>
          <div className="two">
            <div className="studentsec">
              <h2>Student</h2>
              <Link to={"/Studentlogin"}>Student Login</Link>

              <Link to={"/admission"}>Online Enrollment</Link>
              <a href="https://tinyurl.com/vzsju23m">BOSSE Offline Form</a>
              <a href={AICVPSe}>KV Board Offline Form</a>
              <a href={Asiane}>ASIAN UNIVERSITY Offline Form</a>
              <a href={sikkime}>SIKKIM SKILL UNIV Offline Form</a>

              <div class="dropdown show">
                <a
                  class="btn btn-secondary dropdown-toggle propectus-link text-start"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Prospectus
                </a>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item" href={Brocher} download="AICVPS.pdf">
                    Sir Syed ODL
                  </a>
                  <a class="dropdown-item" href={Asian} download="Asian.pdf">
                    Asian International University{" "}
                  </a>
                  <a class="dropdown-item" href={sikkim} download="Sikkim.pdf">
                    Sikkim Skill University
                  </a>
                </div>
              </div>
              <a target="blank" href={bosse} className="visible-class">
                BOSSE AUTHORITY
              </a>
            </div>
            <div className="course_slider">
              <h2>Courses </h2>

              <div className="w3-content w3-display-container bottom-crowsel">
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Scrollbar, A11y, Autoplay]}
                  slidesPerView={1}
                  navigation
                  mousewheel={{
                    forceToAxis: true,
                  }}
                  autoplay={{ delay: 16000 }}
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {images.map((item) => (
                    <SwiperSlide key={item.id}>
                      <div style={{ fontSize: "20px", display: "flex" }}>
                        <img
                          alt="#"
                          className="mySlidess"
                          src={currentImage.src}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainContent;
