import { Box, Typography } from '@mui/material'
import React from 'react'

const TwoelthDetail = () => {
  return (
    <div>
      <div style={{}}>


        <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
          <Typography
            variant="h3"
            color={"#001f5f"}
            fontWeight="bold"
            sx={{ m: "6px 0 5px 0" }}
          >
            12th (HSC) Higher Secondary Program
          </Typography>
          <Typography variant="h5" color={"black"} fontWeight="bold">
            <h6 className='mainhading' style={{ fontSize: "2.5vh" }}> Senior Secondary Programme is equivalent to XIIth standard. Those who have successfully passed Secondary are eligible to take admission to Sr. Secondary Programme of BOSSE. All applicants need to submit a self-attested copy of their Secondary Certificate. There is no restriction on choice of subjects and combinations, except that minimum one language has to be taken. Learners can choose their subjects from the BOSSE List of Subjects as given below.</h6>

          </Typography>
        </Box>


        <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
          <Typography
            variant="h3"
            color={"#001f5f"}
            fontWeight="bold"
            sx={{ m: "6px 0 5px 0" }}
          >
            MARKING SCHEME
          </Typography>
          <Typography variant="h5" color={"black"} fontWeight="bold">
            <div style={{ marginLeft: "2.5rem" }}>
              <h6 className='mainhading' style={{ fontSize: "2.5vh" }}> Examinations are conducted twice a year during April-May and October-November at the specified examination centres, for which notice is released as per the prescribed date sheet.</h6>

            </div>
            <div style={{ marginLeft: "2.5rem" }}>
              <ul className='list-group'>
                <li className='list-group-item'> <Typography variant="h5" color={"ffff"} style={{ marginTop: '20px' }}>2 : The admission is valid for five years and within these five years learners get nine chances to appear in the examination to pass and earn the Secondary or Sr. Secondary certificate. </Typography></li>
                <li className='list-group-item'> <Typography variant="h5" color={"ffff"} style={{ marginTop: '20px' }}>3 : 	Credit Accumulation System is followed; the credits or marks of every subject passed are kept in record and accumulated till all subjects according to the Pass Criteria are passed. .</Typography></li>
                <li className='list-group-item'> <Typography variant="h5" color={"ffff"} style={{ marginTop: '20px' }}>4 : 	To appear in the examination, candidates are required to register by filling up an examination form paying the prescribed examination fee along with the form. </Typography></li>
                <li className='list-group-item'> <Typography variant="h5" color={"ffff"} style={{ marginTop: '20px' }}>5 : 	In order to earn the certificate Secondary or Sr. Secondary, Learners have to obtain at least 33% marks in each subject as well as at least 33% in the aggregate of 5 subjects, including at least one language. In subjects with Practical, one has to pass with 33% marks in Theory and Practical separately. Theory includes 20% Continuous Assessment, which can be in the form of Assignments, Projects, etc. </Typography></li>
                <li className='list-group-item'> <Typography variant="h5" color={"ffff"} style={{ marginTop: '20px' }}>6 : 	Question Papers will be provided in Hindi and English language only. However, students have the flexibility to write the answers in their regional language .</Typography></li>



              </ul>
            </div>
          </Typography>
        </Box>

        <div>

          <div className="Table responsive class mx-auto overflow-auto mt-1" style={{ width: '80%' }}>
            <table className="table table-bordered shadow">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sr. No.</th>
                  <th scope="col">Code</th>
                  <th scope="col">Subject</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>301</td>
                  <td>Hindi</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>302 </td>
                  <td>English</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>303</td>
                  <td>Urdu</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>304</td>
                  <td>Sanskrit</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>305</td>
                  <td>Bangali</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>306</td>
                  <td>Odia</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>307</td>
                  <td>Punjabi</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>308</td>
                  <td>Kannada</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>309</td>
                  <td>Malayalam   </td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>310</td>
                  <td>Tamil</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>311</td>
                  <td>Telugu</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>312</td>
                  <td>Gujarati</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>313</td>
                  <td>Marathi</td>
                </tr>
                <tr>
                  <td>14</td>
                  <td>401</td>
                  <td>Mathematics</td>
                </tr>
                <tr>
                  <td>15</td>
                  <td>402</td>
                  <td>Home Science    </td>
                </tr>
                <tr>
                  <td>16</td>
                  <td>403</td>
                  <td>Psychology</td>
                </tr>
                <tr>
                  <td>17</td>
                  <td>404</td>
                  <td>Geography</td>
                </tr>
                <tr>
                  <td>17</td>
                  <td>405</td>
                  <td>Economics</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td>406</td>
                  <td>Business Studies</td>
                </tr>
                <tr>
                  <td>19</td>
                  <td>407</td>
                  <td>Physics</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>408</td>
                  <td>History</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>409</td>
                  <td>Enviormentel  Science</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>409</td>
                  <td>Chemistry</td>
                </tr>  <tr>
                  <td>20</td>
                  <td>409</td>
                  <td>Political Science</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>409</td>
                  <td>Biology</td>
                </tr>  <tr>
                  <td>20</td>
                  <td>409</td>
                  <td>Accountancy</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>409</td>
                  <td>Introduction to low </td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>409</td>
                  <td>Computer Science</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>409</td>
                  <td>Sociology</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>409</td>
                  <td>Tourism</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>409</td>
                  <td>Physical Education and Yoga   </td>
                </tr>
              </tbody>
            </table>
          </div>


        </div>
      </div>
    </div>
  )
}

export default TwoelthDetail