import { Box, Typography } from '@mui/material'
import React from 'react'

const BordDetails = () => {
  return (
    <div>

      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          Why Choose This Board?
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          Student appeared from this board is eligible to appear NEET & JEE Entrance Exams. As per COBSE (Council of Boards of School Education.) Resolution.
        </Typography>
      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          The Pathway to Higher Studies:
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          The Board of Open Schooling and Skill Education (BOSSE), Sikkim, is an open schooling board that aims to cater to the varied academic needs of the divergent group of students up to pre-degree level including Secondary/Senior Secondary, skill and vocational education. BOSSE is open schooling education board in Sikkim, was established under Act No. 14 of 2020 of the Sikkim Legislative Assembly, passed on 21-09-2020, according to the Sikkim Act 2020 to promulgate and disseminate the open schooling education at state & national level.
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          BOSSE has been granted Equivalence by AIU (Association of Indian Universities) for purposes of Higher Education and Employment. This implies that BOSSE pass outs are eligible to take admission in all Universities, Colleges and Higher Education Institutes throughout the country as well as abroad.
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          BOSSE has also been recognized by NIOS (National Institute of Open Schooling), Ministry of Education, Govt. of India as an open schooling Board. Thus the certificates of BOSSE are recognized at par with other Boards – both national as well as State level Boards of School Education.
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          The Board of Open Schooling and Skill Education (BOSSE) aims to uplift and strengthen open schooling education and create pathways to higher studies across the nation With a flexible education system, combining academic and vocational programmes, the mission of BOSSE is to universalize education and establish equality and justice in society.
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">

          The Board of Open Schooling and Skill Education (BOSSE) provides opportunities to interested learners to choose the following courses/ programmes through open and distance learning mode.

        </Typography>
      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >

          Senior Secondary Programmes

        </Typography>

        <Typography variant="h5" color={"black"} fontWeight="bold">
          Student appeared from this board is eligible to appear NEET & JEE Entrance Exams. As per COBSE (Council of Boards of School Education.) Resolution.
        </Typography>
      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >

          Skills and Vocational Education Programme

        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          Flexibility in subject choice and examination, engaging and interesting study curriculum in Hindi, English and Regional Languages, Transfer of Credits up to four subjects, high-quality academic and employment-oriented programmes, and education at an affordable fee – all these make BOSSE a model Open Schooling Board in India.
        </Typography>

      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >

          How to Take Admission in This Board?

        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          Admission to BOSSE is hassle-free, seamless and secure; interested candidates are required to fill the registration form, along with the requisite Fee, with the required documents to take admission in BOSSE. This can be done either online or at the nearest Admission Centre.
        </Typography>
      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >

          Who Can Take Admission in This Board?

        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          Anyone interested in upscaling his/her qualifications at Secondary/Sr. Secondary level is welcome!  BOSSE’s Open and Distance Learning (ODL) System targets a diverse group of learners including school dropouts, failed students in Board examination, rural youth, working men and women and learners from the economically underprivileged sections of the society to obtain academic  and skill-oriented Vocational Education.
        </Typography>
      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          What is Eligibility Criteria for Secondary (SSC/10)
          and Higher Secondary (HSC/12th)


        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          Any person who has completed 14 years of age can apply for admission to the Secondary Programme, even if he/she has not undergone any formal education earlier. He/she will have to submit a valid proof of age as well as a certificate to the effect that he/she can read and understand the study materials as well as write an examination.
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          A person who has passed class X  can apply for admission to Senior Secondary Programme.
        </Typography>
      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          Medium of Answer

        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          At the moment, BOSSE offers Secondary and Senior Secondary level programmes in Hindi, English medium. Other mediums will be added as per demand.
        </Typography>
      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          Can We Take Vocational Subjects in SSC & HSC?

        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          Yes, to make BOSSE courses more meaningful, vocational courses are offered independently or in combination with academic subjects at the Secondary and the Senior Secondary level. In fact, BOSSE encourages students to offer at least one vocational subject.
        </Typography>
      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          Passing Criteria?

        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          In order to pass, one has to obtain 33% marks in aggregate of 5 subjects as well as a minimum of 33% marks (separately in theory and practical) in individual subjects. Theory also includes marks in Internal Assessment or CA (Continuous Assessment) with 20% weightage.
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          Candidates have to pass in five subjects including at least one language. If a candidate has opted for one or 2 Additional Subjects, then one language and the best four out of the remaining subjects will be considered.
        </Typography>
      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >

          Result Duration After Examination?


        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          BOSSE declares results of Secondary and Senior Secondary programmes and dispatches the certificates within 6 to 8 weeks after the examination ends.
        </Typography>

      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >

          Procedure of Verification ?


        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          All letters/email pertaining verification of marksheets/migration should be sent to verification@bosse.ac.in
          Verification request will be entertained from Universities/Colleges/Schools or from those entities where the student has been hired.
          No charge will be applicable for verification from local institutions or entities. For verification charges from foreign institutions or entities kindly refer to our prospectus.

        </Typography>

      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >

          Board of Open Schooling Offers Convenience and Flexibility to Students:


        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          Candidates get immense flexibility in the board of open schooling. While pursuing academic and vocational programmes through board of open schooling, candidates have flexibilities like: there is no upper age restriction for any of the learning programme, flexibility in choosing subjects, flexibility and convenience in giving the examination. Besides, students also get transfer of credit facility up to two subjects.

        </Typography>

      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          No Need to Attend Class:


        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          Learners don’t need to attend classes physically while pursuing programmes through 12th Open school admission. The trend of e-learning is on the rise. The academic and vocational programmes offered by board of open schooling are available on leading digital portals like Swayam.
        </Typography>

      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          Education is Universal:


        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          Education through 12th Open school admission is suitable for every people. The academic and vocational programmes offered by open school are designed in a lucid manner; which is engaging, interesting and easy to understand, even a layman can understand it. Significantly, programmes are available in several regional languages, which make it more flexible and convenient in the matter of imparting education.
        </Typography>

      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          Offering Continuing Education:


        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          With open school admission candidates can continue their education even they have dropped a few years in between some reason. For example, if any student has completed a few courses of the secondary examination this year and then takes a drop for a year. He/she can complete few other courses according to their flexibility. There is no problem of discontinuity in this education system.
        </Typography>

      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          Employment-Oriented Vocational and Skill Education:


        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          Board of open schooling offers numerous employment-oriented vocational and skill education that helps candidates to get employment opportunities. Some popular vocational programmes are Stenography, Secretarial Practice, beautician course, web development, travel and hospitality programmes, Plant Production, Catering Management, Hotel Front Office Operations, Soil & Fertilizer Management.
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          BOSSE, the Board of Open Schooling and Skill Education is an “Open Board” that is formed to meet the needs of a diverse group of learners up to the pre-degree level. The main goal of this board is to provide universal education to students who are unable to attend traditional schools (for any reason) and want to receive the same education as those who do. The board is responsible for boosting literacy and public awareness about the value of education, as well as providing flexible learning to those sectors and sections of society that are unable to get it. BOSSE, being an Open Board of school education, lowers the obstacles that may arise as a result of prior educational, financial, geographic, time, or disability-related constraints. It also recognizes and accommodates a learner’s preferred learning objectives (programmatic or specific to a course or module), his or her preferred method for completing the learning and demonstrating its completion, and his or her need for student services that will increase the individual’s chances of success.
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          BOSSE has embraced the spirit of open and distance learning, and its new paradigm, which includes shifting from teacher-centered to learner-centered, from instructor to facilitator, from oral to technology-assisted teaching, from fixed time to any time learning, and from education as a one-time activity to education as a lifelong activity. IN India, Open Boards has evolved into one of the most student-friendly educational boards.
        </Typography>

      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          What makes BOSSE so special?


        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          If open learning is to become a viable alternative, it must not be viewed as inferior to the formal system. The importance of quality assurance in the open school learning system cannot be overstated. Because learning is fundamentally a process, hence, open learning is learner-centered. BOSSE has excelled in the use of information and communication technology (ITC). Here are some of the key characteristics that make BOSSE so special:
        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          •	BOSSE is an open board of education for secondary, senior secondary, and skill & vocational education, and follows the school system as other boards like CBSE, ICSE, and state boards do.
          •	BOSSE provides a distance education programme at the 10th and 12th levels as an alternative to formal education.
          •	Students who fail their board examinations in class 10 or 12 can take advantage of the On-Demand Examination option to retake their failed courses in the same year as their board exams.
          •	Students can obtain their marks for two subjects transferred from other recognized boards such as CBSE, CICSE, and any other state board through BOSSE’s Transfer of Credit facility.
          •	BOSSE does not have an upper age limit for any level of the exam, so students of any age can take it. It is also beneficial for school dropouts to continue their education and complete the course within 5 years.
          •	Students can choose from a list of subjects for secondary or senior secondary level, regardless of which stream they enroll in.

        </Typography>

      </Box>
      <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
        <Typography
          variant="h3"
          color={"#001f5f"}
          fontWeight="bold"
          sx={{ m: "6px 0 5px 0" }}
        >
          Open Boards are Favored by:


        </Typography>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          •	Drop-outs from formal schools.
          •	People of rural and remote areas.
          •	Marginalized sections of society like rural youth & Backward classes.
          •	Candidates who have to complete their education later in life.
          •	People who want to study in a self-study environment.
          •	Unemployed and partly employed people.
          •	People with special needs, such as physically and mentally challenged.
          •	Minorities & Female population.
          •	Working population at the bottom level.

        </Typography>

      </Box>
      <Typography variant="h5" color={"black"} fontWeight="bold">
        To sum up, the open boards serve as a link between today’s India and a future India with 100% literacy. The syllabus of BOSSE is based on the National Curriculum Framework, a reference structure developed by NCERT. As a result, open schooling appears to be reaching places where the formal system cannot while retaining educational quality.
      </Typography>
    </div>
  )
}

export default BordDetails