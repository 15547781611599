import { Box, Typography } from '@mui/material';
import React from 'react'

const TenthDetailpage = () => {


  return (
    <div>
      <div>




        <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <Typography
            variant="h3"
            color={"#001f5f"}
            fontWeight="bold"
            sx={{ m: "6px 0 5px 0" }}
          >
            Secondary Programme
          </Typography>
          <Typography variant="h5" color={"black"} fontWeight="bold">
            Secondary Certificate is equivalent to the Xth standard. Eligibility for taking admission to Secondary Programme is minimum 14 years of age as on 31st August  for those who want to take the first exam in April-May and 28/29 February for those who want to take their first exam in October-November. There is no restriction on choice of subjects and combinations, except that minimum one language has to be taken. Learners can choose their subjects from the BOSSE List of Subjects as given below.

          </Typography>
          <Typography variant="h5" fontWeight="bold">
            • Learners have to choose five subjects with at least one and maximum two Language Subjects from Group A and the remaining subjects from Group B.
          </Typography>

          <Typography variant="h5" fontWeight="bold">
            • Two additional subjects can be taken from either of the two groups with additional fees as per BOSSE norms.
          </Typography>
        </Box>

      </div>



      <div>

        <div className="Table responsive class mx-auto overflow-auto mt-1" style={{ width: '80%' }}>
          <caption className='Captions' >Following Table Shows The Subject Names in Curriculum of SSC / Xth in BOSSE.</caption>
          <table className="table table-bordered shadow">


            <thead className="thead-dark">
              <tr>
                <th scope="col">Sr. No.</th>
                <th scope="col">Code</th>
                <th scope="col">Subject</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>101</td>
                <td>Hindi</td>
              </tr>
              <tr>
                <td>2</td>
                <td>102 </td>
                <td>English</td>
              </tr>
              <tr>
                <td>3</td>
                <td>103</td>
                <td>Urdu</td>
              </tr>
              <tr>
                <td>4</td>
                <td>104</td>
                <td>Sanskrit</td>
              </tr>
              <tr>
                <td>5</td>
                <td>105</td>
                <td>Bangali</td>
              </tr>
              <tr>
                <td>6</td>
                <td>106</td>
                <td>Punjabi</td>
              </tr>
              <tr>
                <td>7</td>
                <td>107</td>
                <td>Assamese</td>
              </tr>
              <tr>
                <td>8</td>
                <td>108</td>
                <td>Kannada</td>
              </tr>
              <tr>
                <td>9</td>
                <td>111</td>
                <td>Malayalam   </td>
              </tr>
              <tr>
                <td>10</td>
                <td>112</td>
                <td>Tamil</td>
              </tr>
              <tr>
                <td>11</td>
                <td>112</td>
                <td>Telugu</td>
              </tr>
              <tr>
                <td>12</td>
                <td>113</td>
                <td>Gujarati</td>
              </tr>
              <tr>
                <td>13</td>
                <td>114</td>
                <td>Marathi</td>
              </tr>
              <tr>
                <td>14</td>
                <td>201</td>
                <td>Mathematics</td>
              </tr>
              <tr>
                <td>15</td>
                <td>202</td>
                <td>Science & Technology</td>
              </tr>
              <tr>
                <td>16</td>
                <td>203</td>
                <td>Social Science</td>
              </tr>
              <tr>
                <td>17</td>
                <td>204</td>
                <td>Economics</td>
              </tr>
              <tr>
                <td>17</td>
                <td>205</td>
                <td>Business Studies</td>
              </tr>
              <tr>
                <td>18</td>
                <td>206</td>
                <td>Home Science</td>
              </tr>
              <tr>
                <td>19</td>
                <td>207</td>
                <td>Psychology</td>
              </tr>
              <tr>
                <td>20</td>
                <td>208</td>
                <td>India Culture & Hertiage</td>
              </tr>
              <tr>
                <td>20</td>
                <td>209</td>
                <td>Accountancy</td>
              </tr>
            </tbody>
          </table>
        </div>





      </div>

    </div>



  )
}

export default TenthDetailpage