import { Typography } from "@mui/material";

const MoreAbout = () => {

  const Attestation = [
    {
      href: "https://drive.google.com/file/d/1NV2PMwbhI6YS_foRIcvJCGO10IW3bLi9/view?usp=share_link",
      name: "Board of open schooling & Skill Education (BOSSE) Establishment Act",
    },
    {
      href: "https://drive.google.com/file/d/1nsiojoD-HxDXCF7PrKB78mx6T6Kl7Uhk/view?usp=share_link",
      name: "Association of Indian Universities (AIU) Approval",
    },
    {
      href: "https://drive.google.com/file/d/1IZBGg4zTFUsxFaWoRFuVGbri8lQvBBeG/view?usp=share_link",
      name: "National Institute of Open Schooling and Board of Open Schooling By Govt. of India",
    },

    {
      href: "https://drive.google.com/file/d/1IZBGg4zTFUsxFaWoRFuVGbri8lQvBBeG/view?usp=share_link",
      name: "SSC/HSC (10th/12th )Equivalence to CBSE Govt. Declaration",
    },

    {
      href: "https://drive.google.com/file/d/1JaUFDNCmWFbFmaAspXFToi_W4jWZYZTl/view?usp=share_link",
      name: "Pharmacy Council of India (PCI) Approval",
    },
    {
      href: "https://drive.google.com/file/d/1tfFfBvKoleu5dXEtJBGGk7oN9o7u0gRq/view?usp=share_link",
      name: "Indian Nursing Council (INC) Approval",
    },

    {
      href: "https://drive.google.com/file/d/13PdzAd6tmn_49F3zuOWpEIfkDpyjxrA-/view?usp=share_link",
      name: "ISO Certified Board",
    },
    {
      href: "https://drive.google.com/file/d/13S5DGZzjiigcsVdkl9aIKU7c8jm7NdAn/view?usp=share_link",
      name: "Equivalence Recognition of Board by Maharashtra State Board of Secondary & Higher Secondary Education.",
    },
    {
      href: "https://drive.google.com/file/d/19E_k94ivc4WZmJk5YsxmUZzqDXTRhMeq/view?usp=share_link",
      name: "Shri M. Venkaiah Naidu Appreciation",
    },
    {
      href: "https://drive.google.com/file/d/1sQofVB3OtT7anW9577ZnAXY7AyL8dAWR/view?usp=share_link",
      name: "Om Birla (Speaker of Lok Sabha, India) Appreciation",
    },
    {
      href: "https://drive.google.com/file/d/15Ehzhx7lYqmmunfyRZgKxxOsxLFZN4pM/view?usp=share_link",
      name: "Biswa Bhushan Harichandan (Governor of Andhara Pradesh) Appreciation",
    },
    {
      href: "https://drive.google.com/file/d/120S_8RGJDeiVwIa7mFFDoijFqcAS0zJ8/view?usp=share_link",
      name: "Shri Kalraj Mishra (Governor of Rajasthan)",
    },
    {
      href: "https://drive.google.com/file/d/1cIh5LPdslamfQSqoifCG7k1lJQ7oaELA/view?usp=share_link",
      name: "Shri Lt Gen Gurmit Singh (Governor of Uttarakhand) Appreciation",
    },
    {
      href: "https://drive.google.com/file/d/1EbmqvgGq2LQWTX7ehxXRDcvkkLJ6-hYH/view?usp=share_link",
      name: "Shri. Ramesh Pokhariyal (Ex.Minister of Education, Govt. of India)  Appreciation"

    },
    {
      href: "https://drive.google.com/file/d/1ajWAN40k4dHYh6-KivdiXdcIzqB4HfJt/view?usp=share_link",
      name: "Shri G.P. Upadhyaya, IAS, Additional Chief Secretary, Education Dept. Govt. of Sikkim Appreciation "
    },
  ];
  return (
    <>
      <Typography
        variant="h2"
        fontWeight="700"
        fontFamily="system-ui"
        textAlign="center"
        boxShadow="0 4px 6px rgba(0, 0, 0, 0.2)"
        sx={{ m: "0 0 5px 0", color: "whitesmoke", bgcolor: "#001f5f" }}
      >
        Welcome to Sir Syed Institute of Distance Learning
      </Typography>
      <h1
        className="mainhading"
        style={{
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
          color: "black",
          textAlign: "center",
          backgroundColor: "lightblue",
        }}
      >
        Government Attestation (BOARD BOSSE):
      </h1>

      <div
        style={{ display: "flex", gap: "2vw", flexDirection: "column", textAlign: "center" }}
      >
        {Attestation.map((item) => (

          <div class="dropdown show" style={{ backgroundColor: "white" }} key={item.name}>
            <p
              class="btn  text-black dropdown-toggle  more-about-text"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                fontFamily: "roboto",
                textWrap: "wrap"

              }}
            >
              {item.name}
            </p>

            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuLink"
              style={{ backgroundColor: "lightblue" }}
            >

              <a href={item.href} className="dropdown-item">
                Click here to open the link              </a>
            </div>
          </div>
        ))}

      </div>
    </>
  );
};

export default MoreAbout;
