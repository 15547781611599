import React from 'react'
import pdf1 from '../../AllEnrolmainpdf/computer courses.pdf'
import PdfViewerComponent from '../pdf-preview/pdfPreview';

import { Typography } from "@mui/material";


const  LastComputer = () => {
  const Attestation = [
    {
      href: "https://forms.gle/XU1zK1qDxREjJ8Xf9",
      name: "MS-Office 360  ",
    },
    {
      href: " https://forms.gle/XU1zK1qDxREjJ8Xf9",
      name: "Advance Excel ",
    },
    {
      href: "  https://forms.gle/XU1zK1qDxREjJ8Xf9",
      name: "Excel Dashboard  ",
    },

    {
      href: "  https://forms.gle/XU1zK1qDxREjJ8Xf9",
      name: "	Social Media Marketing & SEO Optimization  ",
    },
    {
      href: " https://forms.gle/XU1zK1qDxREjJ8Xf9 ",
      name: "	Adobe Photoshop"
    },
    {
      href: "https://forms.gle/XU1zK1qDxREjJ8Xf9",
      name: "	CorelDraw "
    },
  ];
  const disableContextMenu = (e) => {
    e.preventDefault();
  };


  return (
    <div onContextMenu={disableContextMenu} >
      <div >


<PdfViewerComponent pdfUrl={pdf1} />
</div>
  <div>

  </div>
  <>

 

      <div
        style={{ display: "flex", gap: "2vw", flexDirection: "column", textAlign: "center"  }}
      >
        {Attestation.map((item) => (

          <div class="dropdown show" style={{ backgroundColor: "white" }} key={item.name}>
            <p
              class="btn  text-black dropdown-toggle  more-about-text"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                fontFamily: "roboto",
                textWrap: "wrap"

              }}
            >
              {item.name}
            </p>

            <div
  className="dropdown-menu"
  aria-labelledby="dropdownMenuLink"
  style={{
    backgroundColor: "lightblue",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Add your desired shadow values here
  }}
>
  <a href={item.href} className="dropdown-item">
    Register Now
  </a>
</div>

          </div>
        ))}

      </div>
    </>
    </div>
  )
}

export default LastComputer










