/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./BurgurBatan.css";
import { Link } from "react-router-dom";

export const BurgurButton = (props) => {
  const [current, setCurrent] = useState(0);
  const [currentState, setCurrentState] = useState(false);
  const navigation = [
    { name: "Home", href: "#" },
    { name: "Admission", href: "Admission" },
    { name: "About Us", href: "AboutUs" },
    { name: "Director Desk", href: "DirectorDesk" },
    { name: "Courses", href: "Diploma" },
    { name: "10-12 Board ", href: "MoreAbout" },
    { name: "Student login", href: "Studentlogin" },
    { name: "Press Realese", href: "PressRelease" },
    { name: "Pravicy Policy", href: "PravicyPolisy" },
    { name: "ContactUs", href: "ContactUs" },
    { name: "MoreAbout", href: "MoreAbout" },
  ];

  useEffect(() => {
    if (currentState) {
      document.getElementById("mySidenav").style.width = "38vh";
    } else {
      document.getElementById("mySidenav").style.width = "0";
    }
  }, [currentState]);
  function openNav(e) {
    setCurrentState(e.target.checked);
  }

  const handleHover = (index) => {
    setCurrent(index);
    setCurrentState(false);
  };
  return (
    <>
      <div className="burger-container">
        <label className="burger left-4 " for="burger">
          <input
            type="checkbox"
            id="burger"
            checked={currentState}
            onChange={openNav}
          />
          <span style={{ background: "#001f5f" }}></span>
          <span style={{ background: "red" }}></span>
          <span style={{ background: "#001f5f" }}></span>
        </label>
      </div>

      <div id="mySidenav" className="sidenav">
        {navigation.map((item, index) => (
          <>
            {item.name === "Courses" ? (
              <div className="dropdown " key={item.href}>
                <a
                  className="dropdown-toggle"
                  style={{ border: "none", fontSize: "25px", color: "#001f5f" }}
                >
                  Courses
                </a>

                <div className="dropdown-content">
                  <Link
                    to={`/diploma`}
                    key={item.name}
                    onClick={() => setCurrentState(false)}
                    aria-current={item.current ? "page" : undefined}
                  >
                    Diploma
                  </Link>
                  <Link
                    to={`/pgCourse`}
                    onClick={() => setCurrentState(false)}
                    key={item.name}
                    aria-current={item.current ? "page" : undefined}
                  >
                    PG Degree
                  </Link>
                  <Link
                    to={`/ugCourse`}
                    key={item.name}
                    onClick={() => setCurrentState(false)}
                    aria-current={item.current ? "page" : undefined}
                  >
                    UG Degree
                  </Link>
                </div>
              </div>
            ) : (
              <Link
                to={`/${item.href}`}
                style={{
                  background: index === current && "grey",
                  color: "#001f5f",
                }}
                key={item.name}
                aria-current={item.current ? "page" : undefined}
                onClick={() => handleHover(index)}
              >
                {item.name}
              </Link>
            )}
          </>
        ))}
      </div>
    </>
  );
};
