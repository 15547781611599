/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import CourseDropDown from "./dropDown/CourseDropDown";
import CounciL from "./dropDown/CounciL";
import { BurgurButton } from "../burgurbtn/BurgurBatan";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom/dist";
//this image is deskstop view
import img1 from "../../files/Slide5.PNG";
import img2 from "../../files/Slide6.PNG";
import img3 from "../../files/Slide7.PNG";
import img4 from "../../files/Slide8.PNG";
//this image is
import mobileImg from '../../images/Slide2.PNG'

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const mainImageSlider = [img1, img2, img3, img4];
const NavBar = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");

  return (
    <div style={{ width: "100%" }}>

      <header className="header_main ">
        {isNonMobile ? (
          <>
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              slidesPerView={1}
              navigation
              mousewheel={{
                forceToAxis: true,
              }}
              autoplay={{ delay: 16000 }}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {mainImageSlider.map((item, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={item}
                    // className="h-full w-full object-cover"
                    style={{ maxWidth: "100%", height: "355px" }}
                    alt="image description"
                  />
                </SwiperSlide>
              ))}
            
            </Swiper>
          </>
        ) : (
         <img src={mobileImg} style={{width:"100%"}}/>
        )}
          {!isNonMobile && <BurgurButton />},
      </header>

      <nav
        id="navbar"
        style={{ zIndex: "10", opacity: "1", position: "relative" }}
      >
        <ul className="nav-bar-list">
          <li>
            <Link to="/">
              <i className="bi bi-house-door"></i>Home
            </Link>
          </li>

          <li>
            <CourseDropDown />
          </li>

          <li>
            <CounciL />
          </li>
          <li className="dropdown">
            <Link className="dropbtn" to="/Studentlogin">
              Students
            </Link>
          </li>
          <li className="dropdown">
            <Link to="/AboutUs" className="dropbtn">
              About Us
            </Link>
          </li>
          <li>
            <Link to="/MoreAbout">About 10th-&-12th Board</Link>
          </li>
          <li>
            <Link to="/DirectorDesk">Director Desk</Link>
          </li>
          <li>
            <Link to="/PressRelease">Blog</Link>
          </li>
          <li>
            <Link to="/ContactUs">Contact Us</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
