import { Box, Typography } from '@mui/material'
import React from 'react'

const SyllubusFeesPage = () => {
  return (

    <div>
      <div >


        <Box padding={'1rem'} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '1rem' }}>
          <Typography
            variant="h3"
            color={"#001f5f"}
            fontWeight="bold"
            sx={{ m: "6px 0 5px 0" }}
          >
            Why Choose This Board?
          </Typography>
          <Typography variant="h5" color={"black"} fontWeight="bold">
            Senior Secondary Programme is equivalent to XIIth standard. Those who have successfully passed Secondary are eligible to take admission to Sr. Secondary Programme of BOSSE. All applicants need to submit a self-attested copy of their Secondary Certificate. There is no restriction on choice of subjects and combinations, except that minimum one language has to be taken. Learners can choose their subjects from the BOSSE List of Subjects as given below.
          </Typography>
        </Box>





      </div>
      <div className="Table responsive class mx-auto overflow-auto mt-1" style={{ width: '90%' }}>
        <table className="table table-bordered shadow">
          <thead className="thead-dark">
            <tr>
              <th scope="col">BOARD OF OPEN SCHOOLING & SKILL EDUCATION</th>
              <th scope="col">SSC FEE AS PER BOARD</th>
              <th scope="col">HSC FEE AS PER BOARD</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>DETAILS</td>
              <td>AMOUNT OF FEES</td>
              <td>AMOUNT OF FEES</td>
            </tr>
            <tr>
              <td>REGISTRATION FEES</td>
              <td>1000 </td>
              <td>1000</td>
            </tr>
            <tr>
              <td>PROGRAM FEES FOR 5 SUBJECTS</td>
              <td>6000</td>
              <td>7000</td>
            </tr>
            <tr>
              <td>FOR EACH ADDITIONAL SUBJECT	1200	1400</td>
              <td>1200</td>
              <td>1400</td>
            </tr>
            <tr>
              <td>PRACTICAL FEE PER SUBJECT (WITH SUBJECTS IN PRACTICALS)</td>
              <td>300</td>
              <td>600</td>
            </tr>
            <tr>
              <td>EXAMINATION FEES PER SUBJECT</td>
              <td>300</td>
              <td>300</td>
            </tr>
            <tr>
              <td>ADDITIONAL FEES FOR PRACTICAL EXAM</td>
              <td>180</td>
              <td>180</td>
            </tr>
            <tr>
              <td>CONSULTANCY FEES</td>
              <td>1000</td>
              <td>2000</td>
            </tr>
            <tr>
              <td>TOTAL FEES</td>
              <td>9980</td>
              <td>12480  </td>
            </tr>
            <tr>
              <td>LATE ADMISSION FEES</td>
              <td>AS PER BOARD CIRCULAR</td>
              <td>AS PER BOARD CIRCULAR</td>
            </tr>
            <tr>
              <td>EXAM COMMENCEMENT</td>
              <td>OCTOBER – NOVEMBER 2023</td>
              <td>OCTOBER – NOVEMBER 2023</td>
            </tr>




          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SyllubusFeesPage