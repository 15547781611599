import React from 'react'
import './StudentInfo.css'
import Dialog from '@mui/material/Dialog';

function StudentInfo(props) {
    const { data, open } = props

    return (
        <>
            <Dialog
                fullScreen
                open={open}


            >
                <div className="student-profile py-4 " style={{ background: '#f0f8ff' }}>


                    <div className="container " >
                        <div className="row gap-3">
                            <div className="col-12 " style={{ background: '#f0f8ff' }}>
                                <div className="card shadow-sm"   >
                                    <div className="card-header  text-center" style={{ backgroundColor: "#163169" }}>
                                        <img className="profile_img" color='white' src={data?.profile || ""} alt="student dp" />
                                        <h3 className='text-white'>{data?.firstName || ' '} {data?.lastName || ""}</h3>
                                    </div>
                                    <div className="card-body">
                                        <p className="mb-0"><strong className="pr-1">Student ID:</strong>{data?.userName || ""}</p>
                                        <p className="mb-0"><strong className="pr-1">Course:</strong>{data?.courseName || " "}</p>
                                        <p className="mb-0"><strong className="pr-1">city:</strong>{data?.city || " "}</p>
                                    </div>
                                </div>
                            </div>
                            <div className=" d-flex flex-column">
                                <div className="card shadow-sm flex-fill p-3 w-100">
                                    <div className="card-header bg-transparent border-0">
                                        <h3 className="mb-0"><i className="far fa-clone pr-1"></i>General Information</h3>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table table-bordered">
                                            <tr>
                                                <th width="30%">Enrollment No</th>
                                                <td width="2%">:</td>
                                                <td>{data?.enrollmentNo || "Pending"}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Roll No</th>
                                                <td width="2%">:</td>
                                                <td>{data?.rollNo || "Pending"}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Academic Year	</th>
                                                <td width="2%">:</td>
                                                <td>2020</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Gender</th>
                                                <td width="2%">:</td>
                                                <td>Male</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Blood</th>
                                                <td width="2%">:</td>
                                                <td>B+</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Total fees</th>
                                                <td width="2%">:</td>
                                                <td>{data?.totalFees}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Paid Fees</th>
                                                <td width="2%">:</td>
                                                <td>{data?.totalPaid}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">SYLLABUS</th>
                                                <td width="2%">:</td>
                                                <td> {data?.syllabus ? <a href={data?.syllabus} target='_blank' rel="noreferrer" className="btn btn-download text-info" style={{ marginLeft: '-0.7rem' }}><span className="bi bi-download mr-1"></span>Download Syllabus</a> : 'Not Available'}</td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Result</th>
                                                <td width="2%">:</td>
                                                <td  >{data?.results && data?.results.map((item, indexNum) => {
                                                    return (
                                                        <a href={item?.link} key={item?.link}  target='_blank' rel="noreferrer" className="btn btn-download text-info" style={{ marginLeft: '-0.7rem' }}><span className="bi bi-download mr-1"></span>{item?.name}</a>
                                                    )
                                                })
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Identity Card</th>
                                                <td width="2%">:</td>
                                                <td > {data?.identyCard ? <a href={data?.identyCard} target='_blank' rel="noreferrer" className="btn btn-download text-info" style={{ marginLeft: '-0.7rem' }}><span className="bi bi-download mr-1"></span>Download Identicard</a> : 'Not Available'}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className="  card shadow-sm flex-fill p-3 ml-1 w-100">
                                    <div className="card-header bg-transparent border-0">
                                        <h3 className="mb-0"><i className="far fa-clone pr-1"></i>Other Information</h3>
                                    </div>
                                    <div className="card-body pt-0">
                                        <p>Our Diploma, UG, & PG Programs are Based of Self Study Mode, We Wont Provide Any Source, Live Interaction or Lectures, But We Have Well Qualified Faculties, If You Need Any Help then You Can Hire Our Faculties As Per Specialization. If You Have Any Balance Fees Then You Can  <a href='/Payment'>Pay Fees</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Dialog>

        </>
    )
}

export default StudentInfo