import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';


export default function MenuListComposition() {

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Stack direction="row" spacing={2} style={{ zIndex: 1 }}>

            <div>
                <Button
                    style={{
                        color: 'white', display: "inline-block",
                        textAlign: "center",
                        padding: "17px 27px", fontWeight: '900', fontSize: "12.5px", textTransform: "capitalize", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"

                    }}
                    ref={anchorRef}
                    onClick={handleToggle}
                >
                    Courses
                </Button>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    style={{ zIndex: 1 }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList style={{ color: 'white', display: 'flex', flexDirection: 'column', height: "193 px", width: "321px", zIndex: "10" }}
                                        autoFocusItem={open}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <div class="dropdown show" style={{ backgroundColor: "black" }}>
                                            <a class="btn  text-white dropdown-toggle backgroundColor-black " role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                10TH (SSC) PROGRAM
                                            </a>

                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: "lightblue" }} >

                                                <a href='/TenthDetailpage ' className="dropdown-item">-	DETAILS OF 10TH </a>
                                                <a href='/SyllubusFeesPage' className="dropdown-item">-	SYLLABUS & FEES (PAGE)</a>
                                            </div>
                                        </div>

                                        <div className="dropdown show" style={{ backgroundColor: "black" }}>
                                            <a className="btn text-white dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                12TH (HSC) PROGRAM
                                            </a>


                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: "lightblue" }}>

                                                <a href='/TwoelthDetail' className="dropdown-item"> DETAILS OF 12TH</a>
                                                <a className="dropdown-item" href="/TwoelhtFesstable">- SYLLABUS & FEES (PAGE)</a>
                                                <a className="dropdown-item" href='/BordDetails'>- BOARD OF O.S.E DETAILS (PAGE)</a>







                                            </div>

                                        </div>



                                        <div style={{ height: "107%", overflow: "overlay", backgroundColor: "black" }}>

                                            <a className='hovercolor' style={{ position: "relative", overflow: "visible" }} href={`/diploma`}>DIPLOMA COURSES </a>
                                            <a className='hovercolor' style={{ position: "relative", overflow: "visible" }} href={`/ugcourse`}>UG-GRADUATE COURSES </a>
                                            <a className='hovercolor' style={{ position: "relative", overflow: "visible" }} href={`/pgCourse`}>PG-POST GRADUATE COURSES</a>
                                            <a href="/LastComputer">LATEST COMPUTER COURSES (PAGE)</a>

                                        </div>

                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </Stack>
    );
}